<template>
  <k-field-group language-prefix="exclusion.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="panels"
      v-on="$listeners"
    >
      <template #panel.0>
        <v-row class="ma-0">
          <CandidateAutocomplete
            v-model="values.candidate"
            field="candidateId"
            grid="col-sm-6 pr-2"
          />
        </v-row>
        <v-row class="ma-0">
          <LocationAutocomplete
            v-model="values.location"
            grid="col-sm-6 pr-2"
            :employer-id="employer.id"
            field="locationId"
            :placeholder="$t('location.allLocations')"
          />
        </v-row>
        <v-row class="ma-0">
          <DepartmentAutocomplete
            v-if="values.location"
            v-model="values.department"
            field="departmentId"
            :location-id="values.location.id"
            grid="col-sm-6 pr-2"
            :placeholder="$t('department.allDepartments')"
          />
        </v-row>
        <v-row class="ma-0">
          <KTextarea
            v-model="values.reason"
            field="reason"
            grid="col-sm-12 pr-2"
          />
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import Exclusion from '@/application/models/Exclusion.js';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import CandidateAutocomplete from '@/modules/candidate/components/CandidateAutocomplete.vue';
import DepartmentAutocomplete from '@/modules/department/components/DepartmentAutocomplete.vue';
import LocationAutocomplete from '@/modules/location/components/LocationAutocomplete.vue';

export default {
  name: 'EmployerCandidateExclusionForm',
  components: {
    KFormDialog,
    KFieldGroup,
    KTextarea,
    LocationAutocomplete,
    DepartmentAutocomplete,
    CandidateAutocomplete,
  },
  computed: {
    panels() {
      return [
        { name: this.$t('exclusion.tabs.general') },
      ];
    },
  },
  props: {
    values: {
      type: Object,
      default: new Exclusion(),
    },
    employer: {
      type: Object,
      required: true,
    },
  },
  watch: {
    'values.location': {
      handler(newLocation) {
        if (newLocation.location) this.values.department = null;
      },
    },
  },
};
</script>
