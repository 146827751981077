import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (employerId, ...args) =>
  getPaginated(`employer/${employerId}/exclusion`, ...args);

/**
 * @param exclusionId {number}
 */
export const show = (exclusionId) =>
  get(`exclusion/${exclusionId}`);

/**
 * @param employerId {number}
 * @param exclusion {Department}
 */
export const create = (employerId, exclusion) =>
  post(`employer/${employerId}/exclusion`, exclusion);

/**
 * @param exclusion {Department}
 */
export const update = (exclusion) =>
  put(`exclusion/${exclusion.id}`, exclusion);

/**
 * @param exclusionId {number}
 */
export const remove = (exclusionId) =>
  destroy(`exclusion/${exclusionId}`);
