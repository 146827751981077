<template>
  <div id="page-exclusion-list">
    <KCrudLayout :search.sync="searchQuery">
      <template #header>
        {{ $tc('exclusion.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="handleOpenCreateDialog"
        >
          {{ $tc('exclusion.exclusionCandidate', 1) }}
        </v-btn>
        <ExclusionForm
          v-model="createDialog"
          :request="createRequest"
          :employer="employer"
          :title="$t('exclusion.exclusionCandidate')"
          :values="createFormValues"
          v-bind="$props"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <k-crud-table
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="exclusion.fields"
          resource="exclusion"
          @click:edit="openUpdate"
        >
          <template #item.locationName="props">
            {{ props.item.locationName || $t('location.allLocations') }} >
            {{ props.item.departmentName || $t('department.allDepartments') }}
          </template>
        </k-crud-table>
      </template>
    </KCrudLayout>
    <ExclusionForm
      v-model="updateDialog"
      :request="updateRequest"
      :employer="employer"
      :title="$t('exclusion.exclusionCandidate')"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Exclusion from '@/application/models/Exclusion.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/employer/api/exclusion.js';
import ExclusionForm from '@/modules/employer/components/EmployerCandidateExclusionForm.vue';

export default {
  name: 'ExclusionIndex',
  components: {
    ExclusionForm,
    KCrudTable,
    KCrudLayout,
  },
  props: {
    employer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      createDialog: false,
      createFormValues: new Exclusion(),
      updateDialog: false,
      updateFormValues: new Exclusion(),
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'candidateName',
          language: 'exclusion.crudHeaders.candidates',
        },
        {
          value: 'locationName',
          language: 'exclusion.crudHeaders.locationOrDepartment',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('employer.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.show' },
        text: this.employer.name,
      },
      {
        exact: true,
        to: { name: 'employer.exclusion' },
        text: this.$tc('exclusion.title', 2),
      },
    ]);
  },
  methods: {
    handleOpenCreateDialog() {
      this.createFormValues = new Exclusion();
      this.createDialog = true;
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    createRequest() {
      return create(this.employer.id, this.createFormValues);
    },
    indexRequest() {
      return index(this.employer.id, ...arguments);
    },
    async openUpdate(item) {
      this.updateFormValues = new Exclusion();
      const exclusionShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(exclusionShowRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
