import Model from '@/application/models/model.js';

export default class Exclusion extends Model {
  id = 0;
  candidate = null;
  location = null;
  department = null;
  reason = '';

  mapForRequest() {
    return {
      candidateId: this.candidate?.id,
      locationId: this.location?.id || null,
      departmentId: this.department?.id || null,
      reason: this.reason,
    };
  }
}
